<template>
    <div>
        <div class="row data-form">
            <div class="col-12" v-if="examScheduleClassrooms && examScheduleClassrooms.length>0">
                <b-tabs content-class="py-5 position-relative" class="line-tabs" v-model="tabIndex">
                    <div class="tabs-bottom-line"></div>
                    <b-tab v-for="classroom in examScheduleClassrooms" :key="classroom.id">
                        <template #title>
							<span class="d-flex align-items-center">
                                {{ classroom.classroom.name }}
							</span>
                        </template>

                        <p class="mb-1 font-weight-bold">{{ $t('add') }}</p>
                        <div class="d-flex mt-1">
                            <div class="flex-grow-1 mr-3">
                                <staff-auto-complete v-model="user"/>
                            </div>
                            <div class="width-100">
                                <b-button class="btn"
                                          variant="primary"
                                          block
                                          @click="add(user, classroom.classroom.id)">
                                    {{ toUpperCase('add') }}
                                </b-button>
                            </div>
                        </div>

                        <div>
                            <p class="mt-5 mb-1 font-weight-bold">{{ $t('observers') }}</p>
                            <div v-if="observers.filter(item=>item.classroom_id==classroom.classroom.id).length > 0">
                                <div class="d-flex border rounded mb-1"
                                     v-for="(observer, observerKey) in observers.filter(item=>item.classroom_id==classroom.classroom.id)"
                                     :key="observerKey">
                                    <div class="flex-grow-1 ml-3 d-flex flex-column justify-content-center">
                                        {{ observer.user.name }} {{ observer.user.surname }}
                                    </div>
                                    <div class="p-1">
                                        <b-button variant="danger"
                                                  @click="remove(observer)">
                                            {{ toUpperCase('delete') }}
                                        </b-button>
                                    </div>
                                </div>
                            </div>
                            <div class="mt-0 mb-0 border rounded p-4" v-else>
                                {{ $t('no_result') }}
                            </div>
                        </div>
                    </b-tab>
                </b-tabs>
                <div class="d-flex justify-content-center mt-2">
                    <b-form-group>
                        <b-button variant="primary" @click="update">
                            {{ $t('save') }}
                        </b-button>
                    </b-form-group>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // Component
    import StaffAutoComplete from '@/components/interactive-fields/StaffAutoComplete';

    // Services
    import ExamScheduleService from '@/services/ExamScheduleService';

    // Other
    import qs from 'qs';
    import StaffService from "../../../services/StaffService";

    export default {
        components: {
            StaffAutoComplete
        },
        props: {
            formId: {
                type: Number,
                default: null
            }
        },
        watch: {
            formId: function(val) {
                this.get(val)
            }
        },
        async created() {
            await this.get()
           this.getStaff()
        },
        data() {
            return {
                tabIndex: null,
                staff:[],

                observers: [],
                examScheduleClassrooms: [],

                user: null,
                classroom_id: null
            }
        },
        methods: {
            get() {
                this.observers = [];
                ExamScheduleService.get(this.formId)
                                   .then(response => {
                                       this.examScheduleClassrooms = response.data.data.exam_schedule_classrooms
                                       let observers = response.data.data.observers
                                       observers.forEach((item) => {
                                           this.observers.push({
                                               id: this.formId,
                                               user: {
                                                   id: item.user_id,
                                                   name: item.full_name
                                               },
                                               classroom_id: item.classroom_id
                                           })
                                       })
                                   })
                                   .catch(e => {
                                       this.showErrors(e)
                                   })
            },

            add(user, classroom_id) {
                let staff=this.staff.filter(item=>{
                    return item.user_id==user
                })
                let newObserver = {
                    id: this.formId,
                    classroom_id: classroom_id,
                    user: {
                        id:staff[0].person.user_id,
                        name:staff[0].person.name,
                        surname:staff[0].person.surname
                    }
                }
                let isThere = this.observers.filter(item => item.user.id == user.id && item.classroom_id == classroom_id)
                if (isThere.length > 0) {
                    return;
                }
                this.observers.push(newObserver)
                this.user = null
            },

            remove(observer) {
                this.$swal
                    .fire({
                        text: this.$t('are_you_sure_to_delete'),
                        showCancelButton: true,
                        confirmButtonText: this.$t('yes'),
                        cancelButtonText: this.$t('no')
                    })
                    .then((result) => {
                        if (result.isConfirmed) {
                            for(var i=0;i<this.observers.length;i++){
                                if(observer.classroom_id==this.observers[i].classroom_id && observer.user.user_id==this.observers[i].user.user_id){
                                    this.observers.splice(i, 1);
                                    break;
                                }
                            }
                        }
                    })
            },

            update() {
                let observerData = [];

                if (this.observers.length > 0) {
                    this.observers.forEach((item) => {
                        observerData.push({user_id: item.user.id, classroom_id: item.classroom_id})
                    })
                }

                let formData = {
                    observers: observerData,
                    paramsSerializer: (observers) => qs.stringify(observers, {encode: false})
                }
                ExamScheduleService.updateObservers(this.formId, formData)
                                   .then(response => {
                                       this.$toast.success(this.$t('api.' + response.data.message));
                                   })
                                   .catch(e => {
                                       this.showErrors(e);
                                   })
            },
            getStaff(){
                let config={
                     params:{
                        limit:-1
                      }
                    }
                StaffService.getAll(config)
                    .then(response=>{
                        this.staff=response.data.data
                    }).catch(err=>{
                        this.showErrors(err)
                })
            }
        }
    }
</script>
